@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  @apply font-sans;
  @apply text-gray-800;
}

@keyframes spinning-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.md-content {
  h1 {
    @apply my-4;
    @apply text-brand-300;
    @apply font-bold;
    @apply border-b;
    @apply text-xl;
  }

  h2 {
    @apply pt-4;
    @apply pb-2;
    @apply text-brand-300;
    @apply font-bold;
  }

  a {
    @apply underline;
    @apply text-brand-300;

    &:hover {
      @apply text-brand-300;
    }
  }

  ul,
  ol {
    @apply list-inside;
    @apply py-2;
  }

  ul {
    @apply list-disc;
  }

  ol {
    @apply list-decimal;
  }

  p {
    @apply py-2;
  }
}
